import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import './DashboardStyle.scss';
import { Row , Col , Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import { connect, intContract } from "../../redux/actions/BlockchainActions";
import { ReducerStateIF } from "../../redux/reducers";
import logo from './../../assets/images/home/logo.png' 


let timeout = null;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const DashboardMain = () => {
    
    const dispatch = useDispatch();
    const blockchain = useSelector((state:ReducerStateIF) => state.blockchain);
    const [loding, setLoding] = useState(false);
    const query = useQuery();
    const [contractBNB, setContractBNB] = useState(0);
    const [contractDetails, setContractDetails] = useState({
        returns: 0,
        apr: 2920,
        devfee: 0,
    });
    const [walletBalance, setWalletBalance] = useState({
        bnb: 0,
        beans: 0,
        rewards: 0,
    });

    const getRef = () => {
        const ref = blockchain.web3.utils.isAddress(query.get("ref"))
          ? query.get("ref")
          : "0x0000000000000000000000000000000000000000";
        return ref;    
    };

    const fetchFeesAndreturn =async () => {
        if (!blockchain.bakedBeans) {
            setContractDetails({
                returns: 0,
                apr: 2920,
                devfee: 0,
            });
            return;
        }
    
        try {
          const [returns, apr, devfee] = await Promise.all([
            Number(await blockchain.bakedBeans.methods.devFeeVal().call().catch((err:any) => {
                return 0;
              })),
            2920,
            Number(await blockchain.bakedBeans.methods.devReturnVal().call()
            .catch((err:any) => {
              return 0;
            }))
          ]);

          setContractDetails({
            returns: returns,
            apr: apr,
            devfee: devfee,
          });

        } catch (err) {
          console.error(err);
          setContractDetails({
            returns: 0,
            apr: 2920,
            devfee: 0
          });
        }
    }

    const fetchContertBalance = async() => {
        if (!blockchain.web3 || !blockchain.bakedBeans) {
            setContractBNB(0);
            return;
        }
        await blockchain.bakedBeans.methods.getBalance().call().then((amount:any) => {
            setContractBNB(blockchain.web3.utils.fromWei(String(amount)));
        });
    }

    const fetchWalletBalance = async () => {
        if (!blockchain.web3 || !blockchain.account) {
            setWalletBalance({
                bnb: 0,
                beans: 0,
                rewards: 0,
            });
            return;
        }
    
        try {
          const [bnbAmount, beansAmount, rewardsAmount] = await Promise.all([
            blockchain.web3.utils.fromWei(String(await blockchain.web3.eth.getBalance(blockchain.account))),
            
            await blockchain.bakedBeans.methods
              .getMyMiners(blockchain.account)
              .call()
              .catch((err:any) => {
                //   console.error("myminers", err);
                return 0;
            }),
            
            Number(blockchain.web3.utils.fromWei(String(await blockchain.bakedBeans.methods
                .beanRewards(blockchain.account)
                .call()
                .catch((err:any) => {
                    // console.error("beanrewards", err);
                    return 0;
                })
                )),
            )
          ]);

          setWalletBalance({
            bnb: bnbAmount,
            beans: beansAmount,
            rewards: rewardsAmount,
          });
        } catch (err) {
          setWalletBalance({
            bnb: 0,
            beans: 0,
            rewards: 0,
          });
        }
    };

    useEffect(() => {
        if(blockchain.bakedBeans === null){
            dispatch(intContract());
        }else{
            fetchFeesAndreturn();
            if(blockchain.account != null){
                fetchContertBalance();
                fetchWalletBalance();
            } 
        }
    },[blockchain]);

    const bakeBnb = async(e:any) => {
        e.preventDefault();
        const bnbval = e.target.bnbval.value;
        const ref = getRef();

        var amount = blockchain.web3.utils.toWei(String(bnbval));

        blockchain.bakedBeans.methods.buyEggs(ref).send({ from: blockchain.account, value: amount})
        .on('transactionHash', function(hash:any){
            waitForReceipt(hash, async function(response:any) {
                if(response.status){
                    fetchContertBalance();
                    fetchWalletBalance();
                }else{
                    console.log(response.msg);
                } 
            });
        }).on('error', function(error:any, receipt:any) {
            console.log(error.message);
        });

    }


    const rebakeBnb = async() => {
        const thisss = this;
        const ref = getRef();
        blockchain.bakedBeans.methods.hatchEggs(ref).send({ from: blockchain.account})
        .on('transactionHash', function(hash:any){
            waitForReceipt(hash, async function(response:any) {
                if(response.status){
                    fetchContertBalance();
                    fetchWalletBalance();
                }else{
                    console.log(response.msg);
                } 
            });
        }).on('error', function(error:any, receipt:any) {
            alert(error.message);
        });

    }


    const eatBnb = async() => {
        blockchain.bakedBeans.methods.sellEggs().send({ from: blockchain.account})
        .on('transactionHash', function(hash:any){
            waitForReceipt(hash, async function(response:any) {
                if(response.status){
                    fetchContertBalance();
                    fetchWalletBalance();
                }else{
                    console.log(response.msg);
                } 
            });
        }).on('error', function(error:any, receipt:any) {
            alert(error.message);
        });

    }


    const waitForReceipt = async(hash:any, cb:any) => {
        const web3 = blockchain.web3;
        const thiss = this;
        web3.eth.getTransactionReceipt(hash, function (err:any, receipt:any) {
            if (err) {
              console.log(err);
            }  
        
            if (receipt !== null) {
              if (cb) {
                  if(receipt.status == '0x0') {
                      cb({status:false, msg: "The contract execution was not successful, check your transaction !"});
                  } else {
                      cb({status:true, msg:"Execution worked fine!"});
                  }
              }
            } else {
              window.setTimeout(function () {
                    waitForReceipt(hash, cb);
              }, 1000);
            }
        });
    }

    return(
        <>
            <div className="MuiBox-root css-8apw0y">
                <div className="css-4znt71">
                    {(blockchain.account == null) && (
                        <button onClick={(e) => {
                            e.preventDefault();
                            dispatch(connect());
                        }} className="topButtton MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButtonBase-root css-1rfik0i" tabIndex={0} type="button">Connect<span className="MuiTouchRipple-root css-w0pj6f"></span></button>
                    )}
                    <div className="css-1qsl8ju">
                        <img src={logo} alt="" style={{marginTop: "-48px"}} />
                        <h6 className="MuiTypography-root MuiTypography-h6 css-3fl4rv">The BNB Reward Pool with the tastiest daily return and lowest dev fee</h6>
                    </div>
                    <div className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-dbec2e">
                        <div className="MuiCardContent-root css-3lnbwi">
                            <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-undefined css-nq5jxc">
                            <p className="MuiTypography-root MuiTypography-body1 css-jojjwf">Contract</p>
                            <h5 className="MuiTypography-root MuiTypography-h5 css-10si35k">{contractBNB} BNB</h5>
                            </div>
                            <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-undefined css-nq5jxc">
                            <p className="MuiTypography-root MuiTypography-body1 css-jojjwf">Wallet</p>
                            <h5 className="MuiTypography-root MuiTypography-h5 css-10si35k">{walletBalance.bnb} BNB</h5>
                            </div>
                            <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-undefined css-nq5jxc">
                            <p className="MuiTypography-root MuiTypography-body1 css-jojjwf">Your Slices</p>
                            <h5 className="MuiTypography-root MuiTypography-h5 css-10si35k">{walletBalance.beans} SLICE</h5>
                            </div>
                            <div className="MuiBox-root css-ddmltu">
                                <form onSubmit={bakeBnb}>
                                    <div className="MuiBox-root css-0">
                                        <div className="MuiBox-root css-79elbk">
                                            <input type="number" min="0.0001" step="0.0001" name="bnbval" className="css-fi9125" defaultValue="0" />
                                            <p className="MuiTypography-root MuiTypography-body1 css-viowja">BNB</p>
                                        </div>
                                    </div>
                                    <div className="MuiBox-root css-5vb4lz">
                                        <button className="MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-fullWidth MuiButtonBase-root Mui-disabled css-4sd5su" tabIndex={-1} type="submit" disabled={(!blockchain.account)?true:false}>BAKE SLICE</button>
                                    </div>
                                </form>
                                <hr className="MuiDivider-root MuiDivider-fullWidth css-39bbo6" />
                                <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-undefined css-nq5jxc">
                                    <p className="MuiTypography-root MuiTypography-body1 css-vlu4wp">Your Rewards</p>
                                    <h5 className="MuiTypography-root MuiTypography-h5 css-j1ayh8">{walletBalance.rewards.toFixed(6)} BNB</h5>
                                </div>
                                <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-undefined css-m7b68o">
                                    <div className="MuiGrid-item css-5y52ga"><button onClick={rebakeBnb} className="MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-fullWidth MuiButtonBase-root css-u1usi" tabIndex={0} type="button">RE-SLICE<span className="MuiTouchRipple-root css-w0pj6f"></span></button></div>
                                    <div className="MuiGrid-item css-xj09ev"><button onClick={eatBnb}className="MuiButton-root MuiButton-contained MuiButton-containedSecondary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-fullWidth MuiButtonBase-root css-u1usi" tabIndex={0} type="button">EAT SLICE<span className="MuiTouchRipple-root css-w0pj6f"></span></button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-1x3lqes">
                        <div className="MuiCardContent-root css-3lnbwi">
                            <h5 className="MuiTypography-root MuiTypography-h5 css-rvkvz4">Nutrition Facts</h5>
                            <div className="MuiBox-root css-1v3caum">
                            <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-undefined css-8rnkcc">
                                <p className="MuiTypography-root MuiTypography-body1 MuiTypography-gutterBottom css-l3b8w4">Daily Return</p>
                                <p className="MuiTypography-root MuiTypography-body1 MuiTypography-gutterBottom css-l3b8w4">{contractDetails.returns}%</p>
                            </div>
                            <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-undefined css-8rnkcc">
                                <p className="MuiTypography-root MuiTypography-body1 MuiTypography-gutterBottom css-l3b8w4">APR</p>
                                <p className="MuiTypography-root MuiTypography-body1 MuiTypography-gutterBottom css-l3b8w4">{contractDetails.apr}%</p>
                            </div>
                            <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-undefined css-8rnkcc">
                                <p className="MuiTypography-root MuiTypography-body1 MuiTypography-gutterBottom css-l3b8w4">Dev Fee</p>
                                <p className="MuiTypography-root MuiTypography-body1 MuiTypography-gutterBottom css-l3b8w4">{contractDetails.devfee}%</p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-1vnafc0">
                        <div className="MuiCardContent-root css-3lnbwi" style={{paddingLeft: "8px",paddingRight: "8px"}}>
                            <h5 className="MuiTypography-root MuiTypography-h5 MuiTypography-gutterBottom css-1s9uqdj">Referral Link</h5>
                            <input readOnly={true} className="css-eyb7os" value={(blockchain.account)?"https://bakedbeans.io?ref="+blockchain.account:""} />
                            <p className="MuiTypography-root MuiTypography-body2 css-dir1dn">Earn 12% of the BNB used to bake slice from anyone who uses your referral link</p>
                        </div>
                    </div>
                    <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 css-qwtwhb">
                        <div className="MuiGrid-item css-1wxaqej"><a href="https://www.bscscan.com/address/0xE2D26507981A4dAaaA8040bae1846C14E0Fb56bF" target="__blank"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAADWElEQVRo3u2av3HbMBSHPycunDsXcsWU4gSmJzA9ge0FaHkCyRPEnkDSBOJxActVSjFVylBdOsglOzYq0qUg7FAQSIkU+Cd3/t2pEIgD34cH4AEPhA91S0d1NWzZngP0lOIoFkHSaRBp+A1wCbgFVVdABLwAYSyCVesglu31gQFwB/QrNhMBU2B+iLcqgUiAbxLClBIJNKkCVBrEsr1HYMj2+DelFXAfiyCsBUTOgRng1ASgahKL4MEoiGV7LvBMfV7IUwRc7TPUPu0BMQAWLUBA6v2FZXs7310IkhlObcoh7chCfS6A6AM/gZOWQQC+np6d99fJ8iWvQpFHZrQznPI0sGzvphSInBduA8b5gB2L4OjtB9ySxhSdZnnzZWtoyYrfaWBIxSK4WCfLDaPXyfL36dn5F/QdeQL8WSfLUH2g88iIbg0pVUOdV3Qgd21bukM90s7OB5GTqW/wpREQyl9isN2hWnCs/L82CLAVkS3bGwFjA+33LNtzs/sxdWjdGAKZ6rYVsQgmhtrf0jtIzomuqlZ1GZxRpAWhmbhhSk+qx7Nz5HzPRhJgDrzK/5ekC0S/IQg/FsGjWpgF2deQq1gEkVpo2d6C+r3qxyK41z3YuY1XFOogpH40CWHZ3kCeViuBtCUVwkFZxv8HkIkC0UOzMz8u12ajSoCHWAS+Uv6MJm+QBVm1bXlGIelw2rDJsr0ZmwtKqAN5pSPSpYIkxEApjnQgIWnSrVOSc2KsgVhlg+L7ZC+bEDOgaE+IBfqM5oa96qo1bxBkugPCAX6RnxDciFsqSG6WwqAeSM/pfgHESEL0c6okKJ2+sfzGIvAt2xtT41G3aCsvU1DqyqTTVuZeFxCnHKaECkt5xgu7IACe1AJdQJxQLdseSoBS9xwyrzxm/+S4r7sc0iax5URT870J+khbSRLgG+V2zAnp/NrqqMJsvHwZpGv2CgOSCY5hSYA33cYimOse1HYZqhjfI40FQ6ofwArvS+q81e2RJjOuOTypkXugqgVEzi1XGu8aajaMRXC1q9Kht7ou6WrzdiXdM9kx7OGJSiByuIzYfZduQk+6JEOeSp0QM8ueUyNABFyUgYDu3bNXjlOd+fLh0EBr+lsUl38JO0dTLZGGR8ASg9+ifKhr+gvf9S3/pZ723AAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFkeXHJZTwAAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=" alt="" width="48" height="48"/></a></div>
                        <div className="MuiGrid-item css-1wxaqej"><a href="https://twitter.com/Pizzaovenbsc" target="__blank"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAD0UlEQVRo3u2aMVbjOBjHf7BTbMF761Ru4247zAnGdNMRLuBJTsBwgiEnAE4A+AKEE+DpttvkBPJ0484UKbbbQjKjcaRYsmNIMf/3eM/Ykvz9pE9fPkmG39ovHey6wTBKAyBWf4GhSA4UpciKvQMJo3QCfAQmwNixWqWgnoBFKbLqXUBUz0+BCw/jt0HdA7ddR6oTSBilU+Aas+v01Ry48R0hL5AwSsfAHZAMAKCrAGalyHLXCn94QCTAM/D3wBAgR3p6NDp+WVerf3YGolzpEfjzDSB0fToaHY/X1eqpN4iCuHtjAF2xC8xWEOVOj+8IocMcrKtVbitgnewqvAqGiUxddWoLAIdbKj3uGQTAo+pgNxA1L5KBjCmAGRCVIjsAToEbQ7nKcC9A/n5tyOhaYZQK7L/WeQ/Iy1JkJqMJozRGhvcCeFBGf7W0EzUzgENDg9MtEEUpslNkOuGrmQ0CoBTZshTZqBTZieqsiy1tbQAeuhTStFAvnWF2B5vmpcic4FX28FV1VmEpNlXlzCBqeMfY9aL14CVwjtmXm3KCUO0WpcjOge8ttkysIMDnlvecNV66AKIWQ73XHmGUXmOZ1DZbmyBJS+VYjZoOUylXi5CZq5fRFn1xKBProfhD86FDA3fASfOm6vUr4Er5b+zYXh/FyMDwM/xq2e02VciweK9GYedytKPWvBTZFfzqWoFDxQXShR6GgPCwo9Zf9YXuWrFDxUSNRDEgiIsdG2UPPSoBjNXQD6mzLpV8QUAmbnGHeq3SgsSbgATA80AjM+laUQcpOsBY0+qOuvAsX9UX+mT3AQFDBtpHYZRe4b8/tqov9BFZejays3W8mhu+owFa57+CqA2xwqORZIeu1XU1mm+ANB84agKIMEqvu07+MErv6BapfklGmyCt+0cGBcgk78J3dBTEtEsHoNZGtTaWui3LXJNyZM6TewAEyDk26QgBcFKKbFn/88FQ4AH7KnGJDHnfkPMp77DWSBSET2c1lesQNpAbZAQJDM/GwC0y+/UFmKh2kx4AtebNG7ZdlC+0r9CWyDmVm9xKpTEx8gAoof8ZSq1cbYC0gyhD/mX4hZGvKuTcKJoPtuVaM9w2Ft5SlzaXtm5ir6vVj6PRcUm/yLJL3Zcim9sebt2NX1er5dHo+PsewLQurVvPR/YAxml/wOnE6h1hnDc5fA9DY2SCNx4YoEJO7HvXCs6HofAaAB6A/xju2GEBnPukPNDvg4ExMpWZ7hDg1hegN4gGFCiYM/xHaYnM7RZ9V5s7/ahG+6CmBvpoMPwFmTEv+35/8lv7rP8Bk+NXaD4vbEEAAAAZdEVYdFNvZnR3YXJlAEFkb2JlIEltYWdlUmVhZHlxyWU8AAAAEnRFWHRFWElGOk9yaWVudGF0aW9uADGEWOzvAAAAAElFTkSuQmCC" alt="" width="48" height="48" /></a></div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default DashboardMain;