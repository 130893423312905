import DashboardMain from '../../components/Dashboard/DashboardMain';
import './DashboardStyle.scss';

const Dashboard = () => {
    return (
        <>
            <DashboardMain />
        </>
    );
}

export default Dashboard;
